import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

import YAMLData from "../../content/projects.yaml"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Anja Schneck UX Design"
      description="Anja is an experienced UX Designer focused on making complex systems easy to use."
      image={data.metaImage.childImageSharp.fixed.src}
    />
    <section className="welcome-section">
      <h2>Hello. Nice to meet you!</h2>

      <p id="hello">
        My Name is Anja. I'm a UX Designer on the mission to shape a more joyful
        and useful digital world. I'm currently learning to code to nourish my
        logic-loving brain.
      </p>
      <div className="growing-line fade-out" id="work"></div>
    </section>

    <h4>Selected Work</h4>
    {YAMLData.map(({ path, teaser, details }, i) => {
      const imgRight = i % 2 !== 0
      const img = data.images.edges.find(
        ({ node }) => node.name === teaser.image
      )

      return (
        <section
          className="project-section"
          key={`project_section_${i}`}
          data-sal="slide-up"
        >
          <Link to={`/work/${path}`}>
            <div
              className={`project-preview-container${
                imgRight ? " reversed" : ""
              }`}
            >
              <div className="image-container" style={{ width: "100%" }}>
                <div className="project-preview-img" style={{ width: "100%" }}>
                  <Img fluid={img.node.childImageSharp.fluid} />
                </div>
              </div>

              <div
                className={`description-wrapper${
                  imgRight ? " right-aligned-text" : ""
                }`}
              >
                <h2>{teaser.headline}</h2>
                <div className="separator"></div>
                <p className="project-description">{teaser.description}</p>
                <p className="project-specs">
                  {details.launch} | {details.client} | {details.role}
                </p>
              </div>
            </div>
          </Link>
        </section>
      )
    })}
  </Layout>
)

export const query = graphql`
  query AllFilesToPathHome {
    images: allFile(filter: { relativePath: { regex: "/projects/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1920) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
    metaImage: file(
      relativePath: {
        regex: "/projects/fraunhofer/Fraunhofer_Mobile-Screens_light/"
      }
    ) {
      childImageSharp {
        fixed(width: 1200) {
          src
        }
      }
    }
  }
`

export default IndexPage
